import { requireFun } from '@/utils'
//新增顶级图库类型
export const sizesPsdsFiled = [
  {
    label: '上传封面显示图',
    prop: 'files',
    name: 'files',
    colNum: 24,
    placeholder: '请上传封面显示图',
    slotName: 'filesSlot'
  }
]
